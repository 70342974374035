import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Styles from './Style.module.css'
const Header = () => {
  const nav = useNavigate();
  return (
    <div className={Styles.navbar}>
        <img src="/Moodys Logo.jpg" alt="logo" onClick={()=>nav('/')} className={Styles.logo} />
        <nav>
      <NavLink className={Styles.navmneu} to='/ciwm'>Ciwm</NavLink>
      <NavLink className={Styles.navmneu} to='/aiwm'>aiwm</NavLink>
      {/* <NavLink className={Styles.navmneu} to='/fourth'>CSC</NavLink> */}
      </nav>
    </div>
  )
}

export default Header
