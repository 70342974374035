import React from 'react'
import styles from '../privacyPolicy/privacyPolicy.module.css';

const PrivacyPolicy = () => {
    const spanStyles = {
        fontWeight: 400, // Make the text bold
    
    
       
      };
  return (
    <div className={styles.container}>
     
    <div className={styles.section}>
         
<h2 className={styles.title}>Privacy Policy</h2>

</div>
<div className={styles.section1}>
<h1 className={styles.heading_01}>Privacy Policy</h1>
      <h2 className={styles.heading_02}> Effective Date: 16th October 2024 </h2>
<p>Your privacy is important to us. This Privacy Policy outlines how PGP Academy Private Limited collects, uses, shares, and protects your information on ciwmasia.org.</p>

<h2 className={styles.heading_02}>  1. Information We Collect   </h2>
<ul>
  <li>Personal Information : <span style={spanStyles}> When you register, enrol, or contact us, we may collect personal details such as your name, email, phone number, and billing information.  </span></li>
  <li>Usage Data : <span style={spanStyles}>We collect non-personal information like IP addresses, browser types, and pages visited for analytics purposes.</span></li>
  
  </ul>
  <h2 className={styles.heading_02}> 2. Use of Information   </h2>
<ul>
  <li> Service Delivery :<span style={spanStyles}> To provide you with courses, certifications, and support.</span></li>
  <li>Communication : <span style={spanStyles}> To send course updates, newsletters, and administrative emails.</span></li>
  <li>Improvement and Personalization : <span style={spanStyles}> To enhance our services based on user feedback.</span></li>
  <li> Legal Compliance :<span style={spanStyles}> To comply with legal obligations and protect our interests.</span></li>

</ul>
<h2 className={styles.heading_02}>   3. Sharing and Disclosure    </h2>
<ul>
  <li> <span style={spanStyles}>Third-Party Service Providers: We may share information with partners, such as payment processors or course providers, solely to facilitate services.</span></li>
  <li> <span style={spanStyles}> Legal Requirements: We may disclose your information if required by law or to protect our legal rights.</span></li>
  
</ul>

<h2 className={styles.heading_02}>   4. Cookies and Tracking     </h2>
<ul>
  <li> <span style={spanStyles}>Our website uses cookies to enhance user experience and analyze website traffic. </span></li>
  <li> <span style={spanStyles}> You can manage cookie preferences via your browser settings.</span></li>
  
</ul>

<h2 className={styles.heading_02}>   5. Data Security    </h2>
<ul>
  <li> <span style={spanStyles}>We employ reasonable technical and organizational measures to safeguard your personal data against unauthorized access or loss.</span></li>
  <li> <span style={spanStyles}> However, no data transmission over the Internet can be guaranteed 100% secure.</span></li>
  
</ul>
<h2 className={styles.heading_02}> 6. User Rights      </h2>
<p>Under applicable law, including India’s IT Act, 2000, and GDPR (if applicable), you have the following rights:</p>
<ul>
  <li> Access and Correction : <span style={spanStyles}>You can access and request correction of your data.</span></li>
  <li> Data Portability : <span style={spanStyles}> You can request a copy of your personal data.
  - Erasure: You can request deletion of your data, subject to legal obligations.</span></li>
  <li> Withdraw Consent : <span style={spanStyles}> Where consent is required, you can withdraw it at any time.</span></li>
  
</ul>

<h2 className={styles.heading_02}>    7. Data Retention      </h2>
<ul>
  <li> <span style={spanStyles}>We retain your personal information for as long as necessary to provide services and comply with legal obligations.</span></li>
 
  
</ul>
<h2 className={styles.heading_02}>  8. Third-Party Links     </h2>
<ul>
  <li> <span style={spanStyles}> Our website may contain links to third-party websites. We are not responsible for the privacy practices of such websites.</span></li>
  
  
</ul>


<h2 className={styles.heading_02}>  9. Changes to This Privacy Policy      </h2>
<ul>
  <li> <span style={spanStyles}> We reserve the right to update this Privacy Policy periodically. Changes will be communicated by posting the updated policy on our website with the "Effective Date" updated.</span></li>
 
  
</ul>
<h2 className={styles.heading_02}>   10. Contact Us       </h2>
<ul>
  <li>  If you have any questions or concerns regarding these policies, please contact us at :<span style={spanStyles}></span></li>
 

<h2 className={styles.heading_02}>PGP Academy Private Limited  </h2>
<p className={styles.textm4}> 
Address: HD-226, Bandra Kurla Complex, WeWork Enam Sambhav, C-20, G Block Road, Bandra East, Mumbai, Mumbai Suburban, Maharashtra, 400051<br/><br/>
Email: info@ciwmasia.org<br/><br/>
Phone: + 91 8100317298</p>
 
  
</ul>




    
     
</div>

       </div> 
  )
}

export default PrivacyPolicy