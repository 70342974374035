import React from 'react'
import styles from '../refundpolicies/refundpolicies.module.css';


      
const Refundpolicies = () => {
    const spanStyles = {
        fontWeight: 400, // Make the text bold
    
    
       
      };
  return (
    <div className={styles.container}>
     
    <div className={styles.section}>
         
<h2 className={styles.title}>Refund policies</h2>

</div>
<div className={styles.section1}>
<h1 className={styles.heading_01}>Refund and Cancellation policies</h1>
 
<ul>
  <li> <span style={spanStyles}> Fees once paid are non-refundable </span></li>
  <li> <span style={spanStyles}>Fees paid for non-transferrable</span></li>
  <li> <span style={spanStyles}>Only one deferral for medical reasons may be considered on a case-to-case basis for each paid registration</span></li>
  
  </ul>

</div>
</div>
  )
}

export default Refundpolicies