import React from 'react'
import styles from "../cantactus/contact.module.css"
const ContactUs = () => {
    const spanStyles = {
        fontWeight: 400, 
    };
  return (
    <div className={styles.container}>
     
     <div className={styles.section}>
          
<h2 className={styles.title}>Contact Us</h2>

</div>
<div className={styles.section1}>
<h1 className={styles.heading_01}>Contact Us</h1>

<h2 className={styles.heading_02}>PGP Academy Private Limited  </h2>
<p className={styles.textm4}> 
Address: HD-226, Bandra Kurla Complex, WeWork Enam Sambhav, C-20, G Block Road, Bandra East, Mumbai, Mumbai Suburban, Maharashtra, 400051<br/><br/>
Email: info@ciwmasia.org<br/><br/>
Phone: + 91 8100317298</p>



     
      
</div>

        </div> 
  )
}

export default ContactUs