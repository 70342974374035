import React from 'react'
import styles from "../footer/footer.module.css"
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className={styles.container}>
          <div className={styles.section}>
          <Link to="/Contact" style={{ textDecoration: 'none' }}>
  <h2 className={`${styles.title} noDecoration`}>Contact Us</h2>
</Link>
<Link to="/TermsandConditions" style={{ textDecoration: 'none' }}>
<h2 className={styles.title}>Terms and Conditions</h2>
</Link>
<Link to="/Privacypolicy" style={{ textDecoration: 'none' }}>
<h2 className={styles.title}>Privacy Policy</h2>
</Link>
<Link to="/Refundpolicy" style={{ textDecoration: 'none' }}>
<h2 className={styles.title}>Refund and Cancellation policies</h2>
</Link>
</div>
    </div>
  )
}

export default Footer