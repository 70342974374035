import React from 'react'
import Styles from './Style.module.css';
import Content from './Content';
import { Link } from 'react-router-dom';
const Third = () => {
  const title = 'Certified International'
  const stitle = 'Wealth Manager (CIWM)'
  const content = 'The leading credential for wealth managers'
  return (
    <div className={Styles.page}>
      <Content title={title} stitle={stitle} content={content} />
      <div className={Styles.padding}>

        <p className="p-4 p-sm-3"></p>
        <h5 className={Styles.head}>What is the Certified International Wealth Manager (CIWM) designation?</h5>
        <p className='py-2 fontSize'>The Certified International Wealth Manager (CIWM) designation is recognized worldwide as a top credential for wealth
          management. It ensures that financial professionals have the unique knowledge and skills to address the complex needs of high-net-worth clients.
        </p>
        <p className='py-2 fontSize'>Co-granted by Moody's and the Association of International Wealth Management, the CIWM has international
          recognition and portability—creating career opportunities for you anywhere in the world.</p>
        <p className='py-2 fontSize'>The Association of International Wealth Management is a professional association for wealth managers worldwide. A not-for-profit based in Switzerland, the AIWM promotes and strengthens global education in private
          banking and wealth management. It was established to:</p>
        <ul className='fs3'>
          <li>Identify and address the needs of high-net-worth clients.</li>
          <li>Respond to the growing demand for sophisticated products and professional client service.</li>
          <li>Navigate the increasing regulation and general complexities of global financial markets. </li>
        </ul>
        <p className="py-1 fontSize">With the launch of the CIWM designation in 2004, the AIWM has set a globally recognized standard for wealth management professionals. </p>
        {/* <br />
      <h5 className={Styles.head}>What skills will you develop?</h5>
      <ul className='fs3'>
        <li>Indian financial landscape</li>
        <li>Client discovery</li>
        <li>Investment management</li>
        <li>Insurance planning</li>
        <li>Financial planning</li>
        <li>Retirement planning</li>
        <li>Estate Planning</li>
      </ul>
      <br />
      <h5 className={Styles.head}>What is the course syllabus?</h5>
      <div className={Styles.headline}>- Accumulating Wealth for Clients Course</div>
      <br />
      <div className={Styles.padding}>
      <a className={Styles.headline} style={{display: 'block'}} href='#redirect'>- Lesson 1 - Overview of the Financial Landscape, Markets, Economy, and Regulations in India</a>
      <p className="py-2 fontSize">India has a well-diversified financial sector. In this lesson we cover the various components of this sector which comprises commercial banks, insurance
companies, mutual funds, pension funds, <b>non-banking financial companies (NBFC)</b>, co-operatives, and other smaller financial entities which provide
nearly 20 per cent of India's GDP.</p>
      <br />
      <p>Topic Covered:</p>
      <ul className='fs3'>
        <li>The Indian Financial System</li>
        <li>Regulatory Framework</li>
        <li>Increasing Digitization</li>
        <li>Growth in the financial services industry</li>
      </ul>
      <div className={Styles.headline}>+ Lesson 2 - Wealth Management Today</div>
      <br />
      <div className={Styles.headline}>+ Lesson 3 - Understanding the Client, Their Budget, and Cash Flows</div>
      </div>
      <br />
      <div className={Styles.headline}>+ Preserving, Converting and Transferring Wealth for Clients Course</div> */}
        <br /><br />
        <h5 className={Styles.head}>What makes the CIWM® so unique?</h5>
        <p className='py-2 fontSize'>The CIWM® training program</p>
        <ul className='fs3'>
          <li>ensures that learners gain in-depth knowledge of international wealth
            management that reflects the cross-border connections and investment of today's wealth owners.</li>
          <li>teaches sophisticated techniques to assess a client's overall financial situation and risks</li>
          <li>takes a holistic approach to a customer's financial and personal situation (accounts, tax, cash flow,
            portfolio, real estate, family etc.)</li>
          <li>focuses on the psychological factors influencing the customer and the advisor using elements of behavioural finance.</li>
          <li>considers the relationship between the advisor and the customer (relationship management)</li>
          <li>has a truly international focus while incorporating the products and services unique to the local markets </li>
        </ul>
        <br /><br />
        <h5 className={Styles.head}>Who should study for the CIWM®?</h5>
        <p className="fontSize py-2">The Certified International Wealth Manager CIWM® is a diploma with a strong practical focus.
          It is aimed at financial, legal and other professionals who already work in the wealth
          management industry and are keen to develop their in-depth knowledge and expertise. CIWM® is tailor-made for:</p>
        <ul className='fs3'>
          <li>Investment advisors</li>
          <li>Financial planners</li>
          <li>Wealth managers</li>
          <li>Asset managers</li>
          <li>Relationship managers, etc.</li>
        </ul>
        <br />
        <h5 className={Styles.head}>What skills will you develop?</h5>
        <ul className='fs3'>
          <li>Indian financial landscape</li>
          <li>Client discovery</li>
          <li>Investment management</li>
          <li>Insurance planning</li>
          <li>Financial planning</li>
          <li>Retirement planning</li>
          <li>Estate Planning</li>
        </ul>
        {/* <br />
        <h5 className={Styles.head}>Who should enrol?</h5>
        <ul className='fs3'>
          <li>Financial advisors</li>
          <li>Mutual fund representatives</li>
          <li>Investment advisor aspirants</li>
        </ul> */}
        <br />
        <h5 className={Styles.head}>How will you learn? </h5>
        <p className='fontSize py-2'>
          CSI's online learning system helps you meet your study goals. You'll benefit from:
        </p>
        <ul className='fs3'>
          <li>Textbook readings available in PDF, e-book and printed format.</li>
          <li>Course materials accessed through your computer, laptop, tablet, or phone, or by downloading the e-book for offline reading.</li>
          <li>Learning objectives that help you to keep the learning focused.</li>
          <li>Knowledge check questions</li>
        </ul>
        <br /><br />
        <h5 className={Styles.head}>PROGRAM OVERVIEW </h5>
        <p className="py-2 fontSize">The course curriculum is split across two modules and is delivered through a self-paced eLearning
          platform. The enrolment period is one year. The program concludes with a
          proctored exam for each module. The two exams can be completed at the same session. </p>
        <br />
        <div className={Styles.headline}>Module 1 : Accumulating Wealth for Clients </div>
        <br />
        <div className={Styles.headline}>Module 2 : Converting & Transferring Wealth and Special Client Focus</div>
        <br /><br />
        <h5 className={Styles.head}>Course Completion, Wall Certificate and Digital Badge</h5>
        <p className="fontSize py-2">
          Upon successfully completing this course, you will accept a digital badge through your student profile. Digital badges are portable image files that allow you to share your credentials across the web. You can post them to your email signature, personal website, social media channels—even to electronic copies of your resume.
        </p>
        <hr className={Styles.hr} />
        <br />
        <div className={Styles.tablecontainer}>
          <h5 className={Styles.head}>What is the exam weighting? </h5>
        </div>
        <div className={Styles.container}>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0 }}>
              <p className="pt-3 px-3 fontSize" style={{ fontWeight: 500 }}>Accumulating Wealth for Clients</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderLeft: 0 }}>
              <p className="pt-3 px-3 fontSize"></p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Indian Financial Landscape</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">4%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Wealth Management Today</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">4%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Understanding the Client, Their Budget and Cash Flows</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">10%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Understanding a Clients Risk Tolerance Utilizing Behavioral Finance Techniques </p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">4%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Introduction to the Portfolio Approach</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">5%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">The Portfolio Management Process</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">4%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Introduction to Equities</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">5%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Fundamental Analysis</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">4%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Technical Analysis</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">4%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Terminology and Features of Fixed Income Securities</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">4%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Organization of the Fixed Income Marketplace</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">2%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Determinants of the General Level of Interest Rates</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">2%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Determinants of Individual Debt Security Yields</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">1%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Price and Yield Calculations for Fixed Income Securities</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">1%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Bond Prices, Duration and Convexity</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">2%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Analyzing and Selecting Mutual Funds</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">6%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Analyzing and Selecting Exchange Traded Funds</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">4%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">An Overview of Derivatives</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">4%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">An Analysis of Alternative Products</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">6%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">International Investing</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">3%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Portfolio Solution Fundamentals</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">6%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Protecting Client’s Investments</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">6%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Portfolio Monitoring and Evaluation</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">4%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Introduction to Digital Assets</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">5%</p>
            </div>
          </span>
        </div>
        <br />
        <div className={Styles.container}>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0 }}>
              <p className="pt-3 px-3 fontSize">Preserving, Converting and Transferring Wealth for Clients</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderLeft: 0 }}>
              <p className="pt-3 px-3 fontSize"></p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Life Stages and Transitions</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">4%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Goal Based Investing</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">6%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Taxation on Various Financial Products and Transactions + Various Income Readings</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">4%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Identifying a Client’s Corporate Banking Needs </p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">8%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Understanding Your Retirement</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">12%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Retirement Accumulation Planning</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">12%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Retirement Income Products</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">5%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Insurance Business in India</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">7%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Personal Risk Management Process </p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">3%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Identification of Risks</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">6%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Protecting Your  Client's Family Wealth + Non-Life Insurance</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">7%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Estate Planning + Married Women’s Property Act</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">6%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Regulatory and Ethical Considerations + Key Regulations + Ethical Issues  + Grievance Redress Mechanism</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">8%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Time Value of Money</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">6%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0, width: "75%" }}>
              <p className="pt-1 px-3 mb-1 fontSize">Investment Product Comparison </p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0, width: "25%" }}>
              <p className="px-3 pt-1 mb-1 fontSize">6%</p>
            </div>
          </span>
        </div>

        <br />
        <br />
        <div className={Styles.tablecontainer}>
          <h5 className={Styles.head}>Exam Information </h5>
        </div>
        <div className={Styles.container}>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 'none' }}>
              <p className="pt-2 px-3 mb-2 fontSize">Exams</p>
            </div>
            <div className={`${Styles.half}`}>
              <p className="pt-2 px-3 mb-2 fontSize">2</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderTop: 0 }}>
              <p className="pt-2 mb-2 px-3 fontSize">Exam Format</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderTop: 0 }}>
              <p className="px-3 pt-2 mb-0 fontSize">Exam 1 : Proctored</p>
              <p className="px-3 pt-1 mb-2 fontSize">Exam 2 : Proctored</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderBottom: 0, borderTop: 0 }}>
              <p className="pt-2 mb-2 px-3 fontSize">Exam Duration</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderBottom: 0, borderTop: 0 }}>
              <p className="px-3 pt-2 mb-0 fontSize">Exam 1 : 3 Hours</p>
              <p className="px-3 pt-1 mb-2 fontSize">Exam 2 : 3 Hours</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderBottom: 0 }}>
              <p className="pt-2 mb-2 px-3 fontSize">Question Format</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderBottom: 0 }}>
              <p className="px-3 pt-2 mb-0 fontSize">Exam 1 : Multiple-choice</p>
              <p className="px-3 pt-1 mb-2 fontSize">Exam 2 : Multiple-choice</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderBottom: 0 }}>
              <p className="pt-2 mb-2 px-3 fontSize">Question Per Exam</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderBottom: 0 }}>
              <p className="px-3 pt-2 mb-0 fontSize">Exam 1 : 70 Questions</p>
              <p className="px-3 pt-1 mb-2 fontSize">Exam 2 : 70 Questions</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderBottom: 0 }}>
              <p className="pt-2 mb-2 px-3 fontSize">Attempts Allowed Per Exam</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderBottom: 0 }}>
              <p className="pt-2 mb-2 px-3 fontSize">3</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0, borderBottom: 0 }}>
              <p className="pt-2 mb-2 px-3 fontSize">Passing Grade</p>
            </div>
            <div className={`${Styles.half}`} style={{ borderBottom: 0 }}>
              <p className="px-3 pt-2 mb-0 fontSize">Exam 1 : 60%</p>
              <p className="px-3 pt-1 mb-2 fontSize">Exam 2 : 60%</p>
            </div>
          </span>
          <span className="d-flex">
            <div className={`${Styles.half}`} style={{ borderRight: 0 }}>
              <p className="pt-2 mb-2 px-3 fontSize">Assignments</p>
            </div>
            <div className={`${Styles.half}`}>
              <p className="px-3 pt-2 mb-2 fontSize">None</p>
            </div>
          </span>
        </div>

        <br />
        <br />
        <h5 className={Styles.head}>Fees for the entire CIWM program paid upfront : Rs. 1,00,000 + GST</h5>
        <p className='fontSize py-2'>
          The fees include the following:
        </p>
        <ul className='fs3'>
          <li>The registration fees          </li>
          <li>Textbook readings available in PDF and e-book</li>
          <li>Course materials accessed through your computer, laptop, tablet, or phone, or by downloading the e-book for offline reading</li>
          <li>Learning objectives that help you to keep the learning focused</li>
          <li>Knowledge check questions</li>
          <li>One examination attempt for each of the two modules</li>
        </ul>

        <br />
        <h5 className={Styles.head}>Fees for each module if paid for separately Accumulating Wealth for Clients  <span style={{ fontWeight: 400 }}>: </span>Rs. 75,000 + GST</h5>
        <p className='fontSize py-2'>
          Accumulating Wealth for Clients: Rs. 75,000 + GST
        </p>
        <p className='fontSize py-2'>
          Preserving, Converting and Transferring Wealth for Clients: Rs. 75,000 + GST
        </p>

        <br />
        <h5 className={Styles.head}>Fees for each examination reattempt <span>: Rs. 10000 + GST</span></h5>


        <br />
        <h5 className={Styles.head}>Refund and Cancellation policies:</h5>
        <ul className='fs3'>
          <li>Fees once paid are non-refundable</li>
          <li>Fees paid for non-transferrable</li>
          <li>Only one deferral for medical reasons may be considered on a case-to-case basis for each paid registration</li>
        </ul>

        <p
          style={{ fontSize: "18px" }}
          className="text-dark text-center pt-5 pb-2"
        >In recent years, representatives from the following companies have participated in the CIWM® programme (Excerpt):</p>
        <hr className={Styles.hr} />
        <div className={Styles.program}>
          <ul className="fs3 px-sm-3 py-1">
            <li>ABN AMRO Bank</li>
            <li>AIG Private Bank</li>
            <li>Bank Julius Baer</li>
            <li>Bank Sarasin</li>
            <li>Bank Vontobel</li>
            <li>Banque Cantonale de Genève</li>
            <li>Banque Cantonale Vaudoise</li>
            <li>Basler Kantonalbank</li>
            <li>Berner Kantonalbank</li>
            <li>BNP Paribas</li>
            <li>BSI</li>
          </ul>
          <ul className="fs3 px-sm-3 py-1">
            <li>Citigroup</li>
            <li>Clariden Leu</li>
            <li>Cornèr Bank</li>
            <li>Crédit Agricole</li>
            <li>Credit Suisse</li>
            <li>Deloitte & Touche Experta</li>
            <li>Deutsche Bank</li>
            <li>Dresdner Bank</li>
            <li>Fortis Banque</li>
            <li>HSBC</li>
            <li>KBL Richelieu</li>
          </ul>
          <ul className="f3 px-sm-3 py-1">
            <li>LGT Bank</li>
            <li>Liechtensteinische Landesbank</li>
            <li>Lloyds TSB Bank</li>
            <li>Lombard Odier Darier Hentsch</li>
            <li>Merrill Lynch</li>
            <li>Mirabaud</li>
            <li>Natixis Securities</li>
            <li>Neue Zürcher Bank NZB</li>
            <li>Nordea Bank</li>
            <li>Pictet</li>
            <li>Rahn & Bodmer</li>
          </ul>
          <ul className="f3 px-sm-3 py-1">
            <li>RBS Coutts Bank</li>
            <li>Reuters</li>
            <li>Rothschild Bank</li>
            <li>Swiss Life</li>
            <li>UBS</li>
            <li>Union Bancaire Privée</li>
            <li>Zürcher Kantonalbank</li>
          </ul>
        </div>
      </div>
      <br /><br />
    </div>
  )
}

export default Third
