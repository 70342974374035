import React from 'react'
import styles from "../termsConditions/termsConditions.module.css"
const TermsConditions = () => {
    const spanStyles = {
        fontWeight: 400, // Make the text bold
    
    
       
      };
  return (
    <div className={styles.container}>
     
    <div className={styles.section}>
         
<h2 className={styles.title}>Terms and Conditions</h2>

</div>
<div className={styles.section1}>
<h1 className={styles.heading_01}>Terms and Conditions</h1>
      <h2 className={styles.heading_02}> Effective Date: 16th October 2024 </h2>
<p>Welcome to ciwmasia.org. These Terms and Conditions govern your use of our website, services, and products. By accessing or using this website, you agree to comply with these terms. If you do not agree, please discontinue use immediately.</p>

<h2 className={styles.heading_02}> 1. Ownership  </h2>
<p>The website ciwmasia.org is owned and operated by PGP Academy Private Limited (referred to as "we", "us", or "our") in line with our exclusive agreement with Moody’s Analytics Global Education (Canada), Inc.  “Moody’s Marks” shall mean the Trademarks and similar designations owned by Moody’s or its affiliates. The Moody’s logo and trademarks are owned by Moody’s Quality Management, Corp., and are used by Moody’s under license. All other content, including but not limited to text, logos, graphics, and trademarks, is the intellectual property of PGP Academy Private Limited.</p>
<h2 className={styles.heading_02}> 2. Use of the Website  </h2>
<ul>
  <li> <span style={spanStyles}>You may use this site for personal, educational, and non-commercial purposes only.</span></li>
  <li> <span style={spanStyles}> You agree not to use this website for any unlawful activity or infringe on the rights of others.</span></li>
  <li> <span style={spanStyles}>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.</span></li>
</ul>
<h2 className={styles.heading_02}>  3. User Accounts   </h2>
<ul>
  <li> <span style={spanStyles}>To access certain features, you may need to register an account. You agree to provide accurate information and update it when necessary.</span></li>
  <li> <span style={spanStyles}>  You are responsible for maintaining the confidentiality of your account and password.</span></li>
  <li> <span style={spanStyles}>We reserve the right to suspend or terminate accounts if fraudulent or suspicious activity is detected.</span></li>
</ul>

<h2 className={styles.heading_02}>  4. Products and Services   </h2>
<ul>
  <li> <span style={spanStyles}>Any certifications, courses, or educational content provided through ciwmasia.org are subject to availability and eligibility.</span></li>
  <li> <span style={spanStyles}> Prices, content, and terms of courses may change without prior notice. We are not liable for any change, suspension, or discontinuation.</span></li>
  
</ul>

<h2 className={styles.heading_02}>   5. Intellectual Property    </h2>
<ul>
  <li> <span style={spanStyles}>All materials provided on the website are copyrighted. You may not reproduce, distribute, or use the content for commercial purposes without explicit permission from PGP Academy Private Limited.</span></li>
  <li> <span style={spanStyles}> Violations may result in legal action.</span></li>
  
</ul>
<h2 className={styles.heading_02}>  6. Disclaimers and Limitation of Liability     </h2>
<ul>
  <li> <span style={spanStyles}>All information on this website is provided "as is." While we make efforts to keep the content accurate and updated, we do not guarantee its completeness or reliability.</span></li>
  <li> <span style={spanStyles}> We shall not be held liable for any loss or damage resulting from your use of the website or reliance on information provided.</span></li>
  
</ul>

<h2 className={styles.heading_02}>   7. External Links       </h2>
<ul>
  <li> <span style={spanStyles}>Our website may contain links to third-party websites. We are not responsible for the content or policies of those websites.</span></li>
 
  
</ul>
<h2 className={styles.heading_02}>  8. Governing Law and Jurisdiction   </h2>
<ul>
  <li> <span style={spanStyles}> These Terms are governed by the laws of India, and any disputes shall be subject to the exclusive jurisdiction of the courts of [Insert City], India.</span></li>
  
  
</ul>


<h2 className={styles.heading_02}>   9. Changes to Terms     </h2>
<ul>
  <li> <span style={spanStyles}>We reserve the right to modify these Terms at any time. Changes will be posted on this page with the "Effective Date" updated.</span></li>
 
  
</ul>




    
     
</div>

       </div> 
  )
}

export default TermsConditions