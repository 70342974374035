import React from "react";
import Styles from "./Style.module.css";
import { useNavigate, useNavigation } from "react-router-dom";

const Home = () => {

  const router = useNavigate();
  return (
    <section className={Styles.frontPage}>
      <div className={`${Styles.home}`}>
        <div className={Styles.rgba}>
          <div className={`${Styles.heading} ${Styles.padding}`}>
            <h5 className="btext">WELCOME TO THE</h5>
            <h2 className="fw-bold opacity-75 heroText">
              Moody's Analytics and <br /> PGP Academy Hub
            </h2>
            <p className="fs3">
              Learn more about our CIWM and AIWM programs that can help  {" "}
              <br />  you achieve your learning and development goals.
            </p>
            <p className="py-2 fs3">
              Please select one of the program tabs above to get started.
            </p>
            <div style={{ display: "flex", gap: 15 }}>
              <button className={Styles.button} style={{ background: "#fff" }} onClick={() => router("/ciwm")}>
                CIWM
              </button>
              <button className={Styles.button} style={{ background: "#0000FD", color: "#fff" }} onClick={() => router("/aiwm")}>
                AIWM
              </button>
            </div>
            {/* <span className='px-2' style={{minWidth: '270px'}}>
          <p style={{fontSize: '14px', color: 'black'}}>About PGP Acadamy</p>
          <img src="/PGP WHITE LOGO 2.png" className={Styles.png} />
            </span> */}
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className={Styles.sideGap}>
        <p className="btext">
          Moody's Analytics has worked with <b>1 million+ learners</b> around the world
        </p>
        <div className='fyp'>
          <span>
            <div className={Styles.twoBox}>
              <div className={Styles.singleBox}>
                <img src="./fkkdfkjk.svg" className={Styles.svg} />
                <span>
                  <h3 className="mb-0 highlight">
                    11,000+
                  </h3>
                  Companies <br /> trained
                </span>
              </div>
              <div className={Styles.singleBox} style={{ borderRight: "0" }}>
                <img src="./Article (1).svg" className={Styles.svg} />
                <span>
                  <h3 className="mb-0 highlight">
                    280+
                  </h3>
                  Courses <br /> offered
                </span>
              </div>
            </div>
            <div className={Styles.twoBox}>
              <div className={Styles.singleBox} style={{ borderBottom: "0" }}>
                <img src="./Article (2).svg" className={Styles.svg} />
                <span>
                  <h3 className="mb-0 highlight">
                    13
                  </h3>
                  languages
                </span>
              </div>
              <div
                className={Styles.singleBox}
                style={{ borderRight: "0", borderBottom: "0" }}
              >
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRUmK6r2ZXfEk_KiuNujRk_1eklc25escgvA&usqp=CAU" className={Styles.svg} />
                <span>
                  <h3 className="mb-0 highlight">
                    148
                  </h3>
                  Countries
                </span>
              </div>
            </div>
          </span>
          <span className="px-3 px-sm-0"></span>
          <div className={`${Styles.between}`}>
            <div className={Styles.country}>
              <h3 className="mb-0 highlight">
                America
              </h3><br />
              <strong className="btext2" style={{ paddingBottom: '-10px' }}>5,300+</strong><br />
              Companies
              <br /><br />
              <strong className="btext2" style={{ paddingBottom: '-10px' }}>650,000+</strong><br />
              Learners
            </div>
            <div className={Styles.country}>
              <h3 className="mb-0 highlight">
                EMEA
              </h3><br />
              <strong className="btext2" style={{ paddingBottom: '-10px' }}>3,800+</strong><br />
              Companies
              <br /><br />
              <strong className="btext2" style={{ paddingBottom: '-10px' }}>200,000+</strong><br />
              Learners
            </div>
            <div className={Styles.country}>
              <h3 className="mb-0 highlight">
                APAC
              </h3><br />
              <strong className="btext2" style={{ paddingBottom: '-10px' }}>1,900+</strong><br />
              Companies
              <br /><br />
              <strong className="btext2" style={{ paddingBottom: '-10px' }}>200,000+</strong><br />
              Learners
            </div>
          </div>
        </div>
      </div>
      <p className='p-2'></p>

      <div className={Styles.padding}>
        <hr className={Styles.hr} />
        <div className="d-block d-sm-flex">
          <span className="px-sm-2 px-3" style={{ minWidth: '270px' }}>
            <p style={{ fontSize: "14px", color: "black" }} className="px-2" >
              About Moody's Analytics
            </p>
            <img src="/Moodys Logo.jpg" className={Styles.png2} />
          </span>
          <span>
            <p className="py-1 w-100 px-2 fs3">
              Moody's Analytics provides financial intelligence and analytical tools to help business leaders make better, faster
              decisions. Our deep risk expertise, expansive information resources, and innovative application of technology help our
              clients confidently navigate an evolving marketplace. We are known for our industry-leading and award-winning solutions,
              made up of research, data, software, and professional services, assembled to deliver a seamless customer experience. We
              create confidence in thousands of organizations worldwide, with our commitment to excellence, open mindset approach, and
              our focus on meeting customer needs.
            </p>
            {/* <span className="px-">
              {/* <button className={Styles.btn}>learn more</button> 
            </span> */}
          </span>
        </div>

        <p className="pt-4"></p>
        <hr className={Styles.hr} />
        <div className="d-block d-sm-flex pb-5">
          <span className="px-sm-2 px-3" style={{ minWidth: "270px" }}>
            <p style={{ fontSize: "14px", color: "black" }} className="px-2" >
              About PGP Acadamy
            </p>
            <img src="/PGP WHITE LOGO 2.png" className={Styles.png} />
          </span>
          <span>
            <p className="py-1 w-100 px-2 fs3">
              PGP Academy is a premier institute for financial planning and wealth management and has been consistently delivering the
              best education in the Banking and Financial Services segment since its inception. PGP is focused adding value to experienced
              and aspiring BFSI professionals through coaching, training, and certification in line with the seismic changes taking place
              in the Banking and Financial Services Industry today. PGP Academy has been a partner to some of the largest and most sought
              -after educational bodies in the world and is currently working with various corporates as well as universities and colleges
              on multiple projects.
            </p>
            {/* <span className="px-">
              {/* <button className={Styles.btn}>learn more</button> 
            </span> */}
          </span>
        </div>
      </div>
    </section>
  );
};

export default Home;
