import './App.css';
import { BrowserRouter , Route , Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Second from './components/Second';
import Third from './components/Third';
import Fourth from './components/Fourth';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/footer/Footer';
import ContactUs from './components/cantactus/ContactUs';
import TermsConditions from './components/termsConditions/TermsConditions';
import PrivacyPolicy from './components/privacyPolicy/PrivacyPolicy';
import Refundpolicies from './components/refundpolicies/Refundpolicies';

function App() {
  return (
    <BrowserRouter>
    <Header />
      <Routes>
         <Route path='/' element={<Home />} />
         <Route path='/aiwm' element={<Second />} />
         <Route path='/ciwm' element={<Third />} />
         <Route path='/fourth' element={<Fourth />} />
         <Route path='/Contact' element={<ContactUs />} />
         <Route path='/TermsandConditions' element={<TermsConditions />} />
         <Route path='/Privacypolicy' element={<PrivacyPolicy />} />
         <Route path='/Refundpolicy' element={<Refundpolicies />} />
       </Routes>
       <Footer/>
    </BrowserRouter>
  );
}

export default App;
